import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Form   from './Form';
import Report from './Report';

const App = () => {
	return (
		<Router>
			<Route exact path='/'                              component={Form}/>
			<Route exact path='/:type/:address/:start?/:stop?' component={Report}/>
		</Router>
	);
}

export default App;
