import graphql from '../graphql';

function execute(type, variables)
{
	return new Promise((resolve, reject) => {
		fetch('https://api.thegraph.com/subgraphs/name/amxx/iexec-poco-v3', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ query: graphql[type], variables })
		})
		.then((response) => response.text())
		.then(raw => resolve(JSON.parse(raw)))
		.catch(reject);
	});
}

export default execute;
