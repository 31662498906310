import React from 'react';

import {
	MDBBtn,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
} from 'mdbreact';

import TaskView from './TaskView';
import Address from './Address';

function shorten(str)
{
	return `${str.substr(0, 18)}...${str.substr(-4)}`;
}

class DealView extends React.Component
{
	render()
	{
		let missing = parseInt(this.props.deal.botSize) - this.props.deal.tasks.length;

		return (
			<MDBCard className='deal flex-row'>
				<MDBCardHeader color={ missing > 0 ? 'red darken-2' : 'unique-color-dark'} className='deal-header rounded-left sideways'>
					<div>
						Deal <Address type='deal' target={this.props.deal.id} className='text-reset'>{ shorten(this.props.deal.id) }</Address>
					</div>
					<div className='font-weight-light'>
						{ (new Date(this.props.deal.startTime*1000)).toUTCString() }
					</div>
				</MDBCardHeader>
				<MDBCardBody className='deal-body d-flex align-content-center flex-wrap overflow-auto'>
					{
						this.props.deal.tasks.map(task => <TaskView key={task.id} account={this.props.account} deal={this.props.deal} task={task}/> )
					}
					{
						missing > 0 &&
						<Address type='deal' target={this.props.deal.id} className='w-100'>
							<MDBBtn className='w-100 m-0 z-depth-1' color='danger' size='sm' outline>
								There are { missing } more unintialized tasks in this deal
							</MDBBtn>
						</Address>
					}
				</MDBCardBody>
			</MDBCard>
		);
	}
}

export default DealView;
