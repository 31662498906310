import React from 'react';

import {
	MDBTable,
	MDBTableHead,
	MDBTableBody,
} from 'mdbreact';

import Address from './Address';

const HIGHLIGHT = 'grey lighten-4';

class TaskView extends React.Component
{
	state = { code: 0 }

	componentDidMount()
	{
		let appPrice        = parseFloat(this.props.deal.appPrice);
		let datasetPrice    = parseFloat(this.props.deal.datasetPrice);
		let workerpoolPrice = parseFloat(this.props.deal.workerpoolPrice);
		let requesterPrice  = appPrice + datasetPrice + workerpoolPrice;

		let operations = {
			'requester': this.props.task.status === 'COMPLETED' ? -requesterPrice : 0,
			'app':       this.props.task.status === 'COMPLETED' ? +appPrice       : 0,
			'dataset':   this.props.task.status === 'COMPLETED' ? +datasetPrice   : 0,
		};
		this.props.task.rewards.forEach(ev => operations[ev.account.id] = []);
		this.props.task.seizes.forEach (ev => operations[ev.account.id] = []);
		this.props.task.rewards.forEach(ev => operations[ev.account.id].push(+parseFloat(ev.value)));
		this.props.task.seizes.forEach (ev => operations[ev.account.id].push(-parseFloat(ev.value)));

		switch (this.props.task.status)
		{
			case 'COMPLETED':
				if (operations['requester'] !== 0)
				{
					let index = operations[this.props.deal.requester.id].indexOf(operations['requester']);
					console.assert(index !== -1, 'could not find requester payment');
					operations[this.props.deal.requester.id].splice(index, 1);
				}
				if (operations['app'] !== 0)
				{
					let index = operations[this.props.deal.appOwner.id].indexOf(operations['app']);
					console.assert(index !== -1, 'could not find app reward');
					operations[this.props.deal.appOwner.id].splice(index, 1);
				}
				if (operations['dataset'] !== 0)
				{
					let index = operations[this.props.deal.datasetOwner.id].indexOf(operations['dataset']);
					console.assert(index !== -1, 'could not find dataset reward');
					operations[this.props.deal.datasetOwner.id].splice(index, 1);
				}
				this.props.task.contributions.forEach(e => {
					console.assert(operations[e.worker.id].length === 1, `Expected exactly one event for worker ${e.worker.id}.`);
				});
				// DO NOT BREAK (state is set similarly to failled tasks)
				// eslint-disable-next-line no-fallthrough
			case 'FAILLED':
				this.setState({
					balances: operations,
					color:    'grey lighten-2',
					code:     1,
				});
				break;

			case 'ACTIVE':
			case 'REVEALING':
				let remaining = parseFloat(this.props.task.finalDeadline)*1000 - Date.now();
				this.setState({
					balances: operations,
					color:    remaining > 0 ? 'green lighten-2 text-white' : 'red darken-2 text-white',
					code:     remaining > 0 ? 2 : 3,
				});
				break;
			default:
				throw new Error(`Unexpected status: ${this.props.task.status}`);
		}
	}

	render()
	{
		return (
			<MDBTable className='task' small responsive>
				<MDBTableHead className='task-header' color={this.state.color}>
					<tr>
						<td colSpan={4}>
							Task <Address type='task' target={this.props.task.id} className={this.state.color}/>
						</td>
					</tr>
				</MDBTableHead>
				{
					this.state.code === 1 ?
						<MDBTableBody className='task-body'>
							{
								this.props.deal.requester &&
								<tr className={ this.props.account === this.props.deal.requester.id ? HIGHLIGHT : ''}>
									<td>Requester</td>
									<td></td>
									<td><Address target={ this.props.deal.requester.id } className='blue-text text-monospace'/></td>
									<td className='text-right'>{ this.state.balances['requester'].toFixed(9) }&nbsp;RLC</td>
								</tr>
							}
							{
								this.props.deal.app &&
								<tr className={ this.props.account === this.props.deal.app.id ? HIGHLIGHT : ''}>
									<td>App</td>
									<td><Address type='app' target={this.props.deal.app.id} className='blue-text'>{ this.props.deal.app.name }</Address></td>
									<td><Address target={ this.props.deal.appOwner.id } className='blue-text text-monospace'/></td>
									<td className='text-right'>{ this.state.balances['app'].toFixed(9) }&nbsp;RLC</td>
								</tr>
							}
							{
								this.props.deal.dataset &&
								<tr className={ this.props.account === this.props.deal.dataset.id ? HIGHLIGHT : ''}>
									<td>Dataset</td>
									<td><Address type='dataset' target={this.props.deal.dataset.id} className='blue-text'>{ this.props.deal.dataset.name }</Address></td>
									<td><Address target={ this.props.deal.datasetOwner.id } className='blue-text text-monospace'/></td>
									<td className='text-right'>{ this.state.balances['dataset'].toFixed(9) }&nbsp;RLC</td>
								</tr>
							}
							{
								this.props.deal.workerpool &&
								<tr className={ this.props.account === this.props.deal.workerpool.id ? HIGHLIGHT : ''}>
									<td>Workerpool</td>
									<td><Address type='workerpool' target={this.props.deal.workerpool.id} className='blue-text'>{ this.props.deal.workerpool.description }</Address></td>
									<td><Address target={ this.props.deal.workerpoolOwner.id } className='blue-text text-monospace'/></td>
									<td className='text-right'>{ this.state.balances[this.props.deal.workerpoolOwner.id].reduce((x,y)=>x+y,0).toFixed(9) }&nbsp;RLC</td>
								</tr>
							}
							{
								this.props.task.contributions.map(contribution =>
								<tr key={contribution.id} className={ this.props.account === contribution.worker.id ? HIGHLIGHT : ''}>
									<td>Worker</td>
									<td>{ /* contribution.status.toLowerCase() */ }</td>
									<td><Address target={ contribution.worker.id } className='blue-text text-monospace'/></td>
									<td className='text-right'>{ (this.state.balances[contribution.worker.id] || []).reduce((x,y)=>x+y,0).toFixed(9) }&nbsp;RLC</td>
								</tr>
								)
							}
							{
								this.state.balances['0x0000000000000000000000000000000000000000'] &&
								<tr>
									<td>Kitty</td>
									<td></td>
									<td></td>
									<td className='text-right'>{ this.state.balances['0x0000000000000000000000000000000000000000'].reduce((x,y)=>x+y,0).toFixed(9) }&nbsp;RLC</td>
								</tr>
							}
						</MDBTableBody>
					: this.state.code === 2 ?
						<MDBTableBody className='task-body'>
						<tr>
						<td>
							Task is still running
						</td>
						</tr>
						</MDBTableBody>
					: this.state.code === 3 ?
						null
					:
						null
				}
			</MDBTable>
		);
	}
}

export default TaskView;
