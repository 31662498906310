export default `
query Worker($id: ID!, $size: Int, $offset: Int, $start: Int, $stop: Int)
{
  contributions(
    first: $size,
    skip: $offset,
    orderBy: timestamp,
    orderDirection: asc,
    where: { worker: $id, timestamp_gt: $start, timestamp_lt: $stop})
  {
    task
    {
      id
      status
      finalDeadline
      contributions
      {
        id
        worker{id}
        status
      }
      rewards{
        account{id}
        value
      }
      seizes{
        account{id}
        value
      }
      deal
      {
        id
        startTime
        app{id name}
        appPrice
        appOwner{id}
        dataset{id name}
        datasetPrice
        datasetOwner{id}
        workerpool{id description}
        workerpoolPrice
        workerpoolOwner{id}
        requester{id}
        botFirst
        botSize
      }
    }
  }
}
`
