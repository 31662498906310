export default `
query Requester($id: ID!, $size: Int, $offset: Int, $start: Int, $stop: Int)
{
  deals(
    first: $size,
    skip: $offset,
    orderBy: startTime,
    orderDirection: asc,
    where: { requester: $id, startTime_gt: $start, startTime_lt: $stop})
  {
    id
    startTime
    app{id name}
    appPrice
    appOwner{id}
    dataset{id name}
    datasetPrice
    datasetOwner{id}
    workerpool{id description}
    workerpoolPrice
    workerpoolOwner{id}
    requester{id}
    botFirst
    botSize
    tasks
    {
      id
      status
      finalDeadline
      contributions
      {
        id
        worker{id}
        status
      }
      rewards{
        account{id}
        value
      }
      seizes{
        account{id}
        value
      }
    }
  }
}
`
