import React from 'react';

import {
	MDBBtn,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBTable,
	MDBTableHead,
	MDBTableBody,
} from 'mdbreact';

import Address from './Address';
import execute from '../services/graph-interface';

class FormAccount extends React.Component
{
	state = {
		requester:   false,
		worker:      false,
		apps:        [],
		datasets:    [],
		workerpools: [],
		modal:       false,
		ready:       false,
	}

	toggle()
	{
		if (!this.state.modal)
		{
			this.setState({ ready: false });
			window.ethereum.enable()
			.then(([ id ]) => {
				this.setState({ modal: true });
				execute('account', {id})
				.then(({data}) => {
					this.setState({
						account:     id,
						requester:   data.account ? data.account.isRequester.length   > 0 : false,
						worker:      data.account ? data.account.contributions.length > 0 : false,
						apps:        data.account ? data.account.apps                     : [],
						datasets:    data.account ? data.account.datasets                 : [],
						workerpools: data.account ? data.account.workerpools              : [],
						ready:       true,
					});
				})
				.catch(console.error)
			})
			.catch(console.error)
		}
		else
		{
			this.setState({ modal: false });
		}
	}

	submit(type, address)
	{
		this.props.onSubmit(type, address);
		this.setState({ modal: false });
	}

	render()
	{
		return (
			<>
				<MDBBtn onClick={this.toggle.bind(this)} color='yellow'>My iExec account</MDBBtn>
				<MDBModal isOpen={this.state.modal} toggle={this.toggle.bind(this)} size='lg' centered>
					<MDBModalHeader toggle={this.toggle.bind(this)} className='text-center'>
						My iExec Account
					</MDBModalHeader>
					<MDBModalBody>
					{
						this.state.ready
						? this.state.requester || this.state.worker || this.state.apps.length || this.state.datasets.length || this.state.workerpools.length
						? <MDBTable responsive small>
								<MDBTableHead color="black" textWhite>
									<tr>
										<th className='align-middle'>Type</th>
										<th className='align-middle'>Details</th>
										<th className='align-middle'>Address</th>
										<th className='align-middle'></th>
									</tr>
								</MDBTableHead>
								<MDBTableBody>
									{
										this.state.requester &&
										<tr>
											<th className='align-middle'>Requester</th>
											<th className='align-middle'></th>
											<th className='align-middle'><Address type='address' target={ this.state.account } className='blue-text text-monospace'/></th>
											<th className='align-middle'><MDBBtn onClick={ this.submit.bind(this, 'requester', this.state.account) } className='m-0' color='yellow' size='sm'>Select</MDBBtn></th>
										</tr>
									}
									{
										this.state.worker &&
										<tr>
											<th className='align-middle'>Worker</th>
											<th className='align-middle'></th>
											<th className='align-middle'><Address type='address' target={ this.state.account } className='blue-text text-monospace'/></th>
											<th className='align-middle'><MDBBtn onClick={ this.submit.bind(this, 'worker', this.state.account) } className='m-0' color='yellow' size='sm'>Select</MDBBtn></th>
										</tr>
									}
									{
										this.state.apps.map(app =>
											<tr key={ app.id }>
												<th className='align-middle'>App</th>
												<th className='align-middle'>{ app.name }</th>
												<th className='align-middle'><Address type='app' target={ app.id } className='blue-text text-monospace'/></th>
												<th className='align-middle'><MDBBtn onClick={ this.submit.bind(this, 'app', app.id) } className='m-0' color='yellow' size='sm'>Select</MDBBtn></th>
											</tr>
										)
									}
									{
										this.state.datasets.map(dataset =>
											<tr key={ dataset.id }>
												<th className='align-middle'>Dataset</th>
												<th className='align-middle'>{ dataset.name }</th>
												<th className='align-middle'><Address type='dataset' target={ dataset.id } className='blue-text text-monospace'/></th>
												<th className='align-middle'><MDBBtn onClick={ this.submit.bind(this, 'dataset', dataset.id) } className='m-0' color='yellow' size='sm'>Select</MDBBtn></th>
											</tr>
										)
									}
									{
										this.state.workerpools.map(workerpool =>
											<tr key={ workerpool.id }>
												<th className='align-middle'>Workerpool</th>
												<th className='align-middle'>{ workerpool.description }</th>
												<th className='align-middle'><Address type='workerpool' target={ workerpool.id } className='blue-text text-monospace'/></th>
												<th className='align-middle'><MDBBtn onClick={ this.submit.bind(this, 'workerpool', workerpool.id) } className='m-0' color='yellow' size='sm'>Select</MDBBtn></th>
											</tr>
										)
									}
								</MDBTableBody>
							</MDBTable>
						: <>No account for { this.state.account }</>
						: <>Loading ...</>
					}
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default FormAccount;
