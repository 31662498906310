import React from 'react';
import { ethers } from 'ethers';

import {
	MDBBtn,
	MDBInputGroup,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardImage,
	MDBCardBody,
} from 'mdbreact';

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import FormAccount from './FormAccount';

import iExecLogoHorizontal from '../assets/iExec-logo-horizontal-white.svg';
import '../css/Form.css';

function isAddress(value)
{
	try
	{
		return ethers.utils.getAddress(value);
	}
	catch
	{
		return false;
	}
}

class Form extends React.Component
{
	state = {
		type:         undefined,
		address:      "",
		startDate:    undefined,
		endDate:      undefined,
		focusedInput: null,
	}

	handleChange(ev)
	{
		this.setState({ [ev.target.name]: ev.target.value })
	}

	setRessource(type, address)
	{
		this.refs['type-select'  ].value = type;
		this.refs['address-input'].value = address;
		this.setState({ type, address });
	}

	submit(ev)
	{
		ev.preventDefault();

		if (!this.state.type || !isAddress(this.state.address))
		{
			alert('Invalid input: type must be set and address must be valid');
			console.error('Invalid input: type must be set and address must be valid');
			console.info(`type:    ${this.state.type}`);
			console.info(`address: ${this.state.address}`);
			return;
		}

		const from =
			this.state.startDate
			? `/${this.state.startDate._d.toISOString().substr(0,10)}T00:00:00Z`
			: this.state.endDate
			? '/0'
			: '';

		const to  =
			this.state.endDate
			? `/${this.state.endDate._d.toISOString().substr(0,10)}T23:59:59Z`
			: '';

		this.props.history.push(`/${this.state.type}/${this.state.address}${from}${to}`);
	}


	render()
	{
		return (
			<div id='Form'>
				<MDBContainer>
					<MDBRow center>
						<MDBCol xl='6' lg='7' md='9' sm='12'>
							<MDBCard className='z-depth-5'>
								<MDBCardImage className='img-fluid rounded-top black' src={iExecLogoHorizontal}/>
								<MDBCardBody>
									<form onSubmit={ this.submit.bind(this) }>
										<div className='d-flex flex-row form-group'>
											<MDBInputGroup
												type='text'
												hint='Address'
												value={ this.state.address }
												inputs={
													<input
														className='flex-1'
														name='address'
														value={ this.state.address }
														onChange={ this.handleChange.bind(this) }
														placeholder='Address'
														ref='address-input'
													/>
												}
												append={
													<select
														name='type'
														value={ this.state.type }
														onChange={ this.handleChange.bind(this) }
														ref='type-select'
													>
														<option value='' hidden='hidden'>Type</option>
														<option value='requester' >Requester </option>
														<option value='worker'    >Worker    </option>
														<option value='app'       >App       </option>
														<option value='dataset'   >Dataset   </option>
														<option value='workerpool'>Workerpool</option>
													</select>
												}
											/>
										</div>
										<div className='form-group'>
											<DateRangePicker
												startDateId              = 'iexec_report_start_date'
												endDateId                = 'iexec_report_end_date'
												startDatePlaceholderText = 'from'
												endDatePlaceholderText   = 'to'
												displayFormat            = 'YYYY-MM-DD'
												startDate                = { this.state.startDate    }
												endDate                  = { this.state.endDate      }
												focusedInput             = { this.state.focusedInput }
												onDatesChange            = { ({ startDate, endDate }) => this.setState({ startDate, endDate }) }
												onFocusChange            = { focusedInput => this.setState({ focusedInput }) }
												withPortal               = { true }
												firstDayOfWeek           = { 1 }
												minimumNights            = { 0 }
												isOutsideRange           = { () => false }
											/>
										</div>
										<FormAccount onSubmit={ this.setRessource.bind(this) }/>
										<MDBBtn color='dark' type='submit'>Generate report</MDBBtn>
									</form>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}
}

export default Form;
