import React from 'react';

class Address extends React.Component
{
	render()
	{
		return (
			<a href={`https://explorer.iex.ec/mainnet/${this.props.type || 'address'}/${this.props.target}`} target='_blank' rel='noopener noreferrer' className={this.props.className}>
				{ this.props.children || this.props.target }
			</a>
		);
	}
}

export default Address;
