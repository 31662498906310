import React from 'react';

import {
	MDBJumbotron,
} from 'mdbreact';

import execute from '../services/graph-interface';
import Loading from './Loading';
import DealView from './DealView';
import Address from './Address';

// import iExecLogoVertical from '../assets/iExec-logo-vertical-black.svg';
import '../css/Report.css';

const BATCHSIZE = 100;

function tryTimestamp(raw)
{
	const d = new Date(raw);
	return !isNaN(d) ? d : null;
}

function toTimestamp(raw)
{
	return tryTimestamp(raw) || tryTimestamp(parseInt(raw) * 1000);
}

class Report extends React.Component
{
	constructor(props)
	{
		super(props);
		this.type    = this.props.match.params.type.toLowerCase();
		this.address = this.props.match.params.address.toLowerCase();
		this.start   = toTimestamp(this.props.match.params.start) || new Date(0);
		this.stop    = toTimestamp(this.props.match.params.stop ) || new Date(Date.now());
		this.state   = { data: [], done: false };
	}

	componentDidMount()
	{
		this.process()
		.then(_ => console.info(`Report generation complete: ${this.state.data.length} entries found`))
		.catch(console.error)
	}

	process()
	{
		return new Promise(async (resolve, reject) => {
			let chunk;
			do
			{
				chunk = await execute(
					this.type,
					{
						id:     this.address,
						size:   BATCHSIZE,
						offset: this.state.data.length,
						start:  Math.floor(this.start.getTime() / 1000),
						stop:   Math.floor(this.stop.getTime()  / 1000),
					}
				);
				switch (this.type)
				{
					case 'app':
					case 'dataset':
					case 'workerpool':
					case 'requester':
						this.state.data.push(...chunk.data.deals);
						chunk.length = chunk.data.deals.length;
						break;
					case 'worker':
						this.state.data.push(...chunk.data.contributions.map(contribution => ({ ...contribution.task.deal, tasks: [ contribution.task ] })));
						chunk.length = chunk.data.contributions.length;
						break;
					default:
						throw new Error(`Unsuported type: ${this.type}`);
				}
				console.debug(`→ batch (${chunk.length}/${this.state.data.length})`);
			}
			while (chunk.length === BATCHSIZE);
			this.setState({ done: true });
			resolve();
		});
	}


	render()
	{
		return (
			this.state.done
			?
				<div id='Report'>
					<MDBJumbotron className="m-3">
						<h3>iExec activity report</h3>
						<hr className="my-3" />
						<div>
							<span className='text-capitalize'>{ this.type }</span>: <Address type={ this.type } target={ this.address }/>
						</div>
						<div className='d-flex justify-content-center'>
							From:&nbsp;{ this.start.toUTCString() } &mdash; To:&nbsp;{ this.stop.toUTCString() }
						</div>
						<div>{ this.state.data.length } entries found for that interval</div>
					</MDBJumbotron>
					<div className='container d-flex flex-column'>
						{ this.state.data.map(deal => <DealView key={deal.id} account={this.address} deal={deal}/> ) }
					</div>
				</div>
			:
				<Loading/>
		);
	}
}

export default Report;
