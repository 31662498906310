export default `
query Account($id: ID!)
{
  account(id: $id)
  {
    apps{id name}
    datasets{id name}
    workerpools{id description}
    isRequester(first:1){id}
    contributions(first:1){id}
  }
}
`
